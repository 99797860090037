<template>
  <div class="list-item">
    <div class="list-item-container">
      <h5 class="sn">{{ index }}. &nbsp;</h5>
      <div class="from">
        <p style="text-align: left">{{ to ? "From:" : "Title" }}</p>
        <h3 class="item">{{ to ? `${from} -` : from }} &nbsp;</h3>
      </div>
      <div class="to" v-if="to">
        <p style="text-align: left">to:</p>
        <h3 class="item">{{ to }}</h3>
      </div>
    </div>
    <div class="review">
      <p v-if="isReviewed">
        <span
          style="color: black;font-weight: normal; font-size: x-large;font-family: 'Sriracha', cursive"
          >Review: </span
        ><span style="">{{ review }}</span>
      </p>
      <p v-else>
        Waiting For review
      </p>
    </div>
    <div class="download">

      <button class="btn download-btn-user btn-primary" @click="download">
        Download Time Table
      <img
        :src="img"
        title="download time table"
        width="13"
        height="13"
      />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeTableListItem",
  emits: ["downloadExcel", "downloadAdmin"],
  props: ["index", "from", "to", "isReviewed", "review", "img"],
  methods: {
    download() {
      this.$emit("downloadExcel");
    },
    downloadAdmin() {
      this.$emit("downloadAdmin");
    }
  },
  data() {
    return {
      // img: require("/src/assets/download.svg")
    };
  }
};
</script>

<style scoped>
.list-item-container {
  display: flex;
  /*border: 1px solid gray;*/
  width: 50vw;
  margin: 0 auto;
  padding: 10px;
}

.list-item {
  position: relative;
  border: 1px solid wheat;
}

.sn {
  /*pad*/
  margin: auto 0;
}

.item {
  margin: auto 0;
  font-family: "Adobe Devanagari";
  line-height: 2px;

  font-size: xx-large;
}

.review {
  margin-left: 40px;
  text-align: left;
}

.review {
  order: 2;
  opacity: 0.8;
  /*margin: auto 0 auto auto;*/
}

.download {
  margin-left: auto;
  order: 2;
  top: 30%;
  right: 0;
  position: absolute;
  height: 32px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
}

.download-btn-admin {
  color: #008000;

  background-color: #2cd02c;
}

.download-btn-user {
  padding-bottom: 30px;
  margin-right: 5px;
}


@media only screen and (max-width: 800px) {
  .list-item-container {
    width: 90vw;
  }
}
</style>
